dmx.Component('bs5-toasts', {

  attributes: {
    position: {
      type: String,
      default: 'top',
      enum: ['top', 'bottom'],
    },

    align: {
      type: String,
      default: 'right',
      enum: ['left', 'right'],
    },

    offsetX: {
      type: Number,
      default: 15,
    },

    offsetY: {
      type: Number,
      default: 15,
    },

    minWidth: {
      type: Number,
      default: 240,
    },

    maxWidth: {
      type: Number,
      default: 350,
    },

    zIndex: {
      type: Number,
      default: 10000,
    },

    delay: {
      type: Number,
      default: 5000,
    }
  },

  methods: {
    clear () {
      this._clear();
    },

    show (options) {
      this._show(options);
    }
  },

  init (node) {
    dmx.bs5toast.setup(this.props);
  },

  performUpdate (updatedProps) {
    dmx.bs5toast.setup(this.props);
  },

  _clear () {
    dmx.bs5toast.clear();
  },

  _show (options) { // options: title, message, delay, closable
    dmx.bs5toast.show(options);
  }

});